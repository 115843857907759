import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {Button} from 'components/forms';
import {getImage} from 'assets/images/svg-image';

const Container = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 20px;

  button {
    margin-left: auto;
    height: 28px;
  }
`;

const Title = styled.span`
  height: 20px;
  line-height: 20px;
  margin: 0;
  font-size: 20px;
  color: #575660;
  font-weight: 700;
`;

type IProps = {
  title?: string;
  onClose(): void;
};

function PopupHeader({title = 'Popup', onClose}: IProps): ReactElement {
  return (
    <Container>
      <Title>{title}</Title>
      <Button variant="none" onClick={onClose}>
        <img src={getImage('close')} className="close-button" alt="close button" />
      </Button>
    </Container>
  );
}

export default PopupHeader;
