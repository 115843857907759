import Button from 'components/forms/Button';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {BasicSpinner} from 'components/common';
import {faTableCells} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

const Container = styled.div`
  //pointer-events: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 30px;
  margin: 8px 0 5px 0;

  .data-spreadsheet-button {
    display: flex;
    align-items: center;
    text-align: right;
    position: relative;

    span {
      flex: 1;
    }
    > svg {
      margin-left: 3px;
    }
  }
`;

type IProps = {
  dateRangeTo?: string;
  isLoading: boolean;
  onClickSpreadsheet(): void;
  onClick(): void;
  // onCloseTooltip(): void;
};

function CopilotLeftDate({dateRangeTo, isLoading, onClick, onClickSpreadsheet}: IProps) {
  const [msg, setMsg] = useState('');
  useEffect(() => {
    if (dateRangeTo) {
      const daysDiff = dayjs().diff(parseInt(dateRangeTo) * 1000, 'day');
      let str: string;
      if (daysDiff === 0) {
        str = 'today.';
      } else if (daysDiff === 1) {
        str = `yesterday.`;
      } else {
        str = `${daysDiff} days ago.`;
      }
      setMsg(str);
    }
  }, [dateRangeTo]);

  /*  const handleClick = () => {
    onClick();
    onCloseTooltip();
  };

  const handleClickSpreadsheet = () => {
    onClickSpreadsheet();
    onCloseTooltip();
  };*/

  return (
    <Container>
      You have data from {msg}
      <br /> Would you like to change the data time range?
      <br />
      <ButtonContainer>
        <Button className="new-time-range-button" width={140} onClick={onClick}>
          Create New Chat
        </Button>
        <Button className="data-spreadsheet-button" width={160} onClick={onClickSpreadsheet}>
          {!isLoading && <FontAwesomeIcon icon={faTableCells} />}
          <BasicSpinner isShow={isLoading} size="xs" type="overlay" position="center-left" margin={2} />
          <span>Open Spreadsheet</span>
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default CopilotLeftDate;
