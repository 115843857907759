import {ChangeEvent, ReactElement, ReactNode, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, TextForm} from 'components/forms';
import styled from 'styled-components';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {NEW_FILE_ROUTE} from 'components/menu/constants';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {useReactFlow} from 'reactflow';
import {capitalize} from 'utils/commons';
import {AuthContext} from 'components/auth/AuthProvider';

const ValidMessage = styled.p`
  color: tomato;
`;

const WarningMessage = styled.p``;

export type ICloseWarningProcessCanvas = {
  title: string;
  content: string | ReactNode;
  fileName: string;
  isSaveAs: boolean;
  actionType: string;
  confirmLabel?: string;
  confirmLabelWidth?: number;
  confirmAction?(): void;
};

function ProcessCanvasCloseWarningModal(): ReactElement {
  const {userProfile} = useContext(AuthContext);
  const {closeModalState, file, saveAsModalState, save} = useContext(ProcessCanvasContext);
  const [closeModalStateElement, setCloseModalStateElement] = closeModalState;
  const [, setSaveFileModalData] = saveAsModalState;
  const [text, setText] = useState(closeModalStateElement?.fileName || '');
  const [validMessage, setValidMessage] = useState('');
  const reactFlow = useReactFlow();

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onCancel() // todo meta pfd enter key 이미 사용중. 다른 key로 교체 필요
  });

  useEffect(() => {
    if (closeModalStateElement) {
      setText(closeModalStateElement?.fileName || '');
    }
  }, [closeModalStateElement]);

  const onClose = (): void => {
    const copy = closeModalStateElement;
    setCloseModalStateElement(undefined);
    copy?.confirmAction();
  };

  const onChangeText = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    setText(value);
    setValidMessage(undefined);
  };

  const onCancel = (): void => {
    setCloseModalStateElement(undefined);
  };

  const onClickConfirm = (): void => {
    if (!text?.trim()) {
      setValidMessage('Please input file name.');
      return;
    } else if (userProfile.username !== file.owner) {
      setCloseModalStateElement(undefined);
      setSaveFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true});
    } else {
      save(text, reactFlow.getNodes(), reactFlow.getEdges(), closeModalStateElement.isSaveAs).then((fileData) => {
        const copy = closeModalStateElement;
        setCloseModalStateElement(undefined);
        copy?.confirmAction();
      });
    }
  };

  const {id} = useParams();

  if (closeModalStateElement) {
    return (
      <ModalWrapper type="sm">
        <ModalHeader onClose={onCancel}>{closeModalStateElement.title}</ModalHeader>
        <ModalBody>
          <WarningMessage>{closeModalStateElement.content}</WarningMessage>
          {id === NEW_FILE_ROUTE && (
            <>
              <TextForm value={text} width={400} placeholder="Input file name" onChange={onChangeText} />
              <ValidMessage>{validMessage}</ValidMessage>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            width={closeModalStateElement?.confirmLabelWidth ? closeModalStateElement?.confirmLabelWidth : 100}
            onClick={onClickConfirm}
          >
            {closeModalStateElement?.confirmLabel ? closeModalStateElement?.confirmLabel : 'Save'}
          </Button>
          {closeModalStateElement.actionType !== 'export' && (
            <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
              {capitalize(closeModalStateElement?.actionType)} Without Saving
            </Button>
          )}
          <Button className="modal-btn" variant="cancel" size="sm" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalWrapper>
    );
  } else {
    return undefined;
  }
}

export default ProcessCanvasCloseWarningModal;
