import {ChangeEvent, forwardRef, ReactElement, RefObject, useRef, useState} from 'react';
import {Button, SwitchForm} from 'components/forms';
import styled from 'styled-components';
import {faAngleLeft, faAngleRight} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import {ISeries, ITimestampRangeState} from 'components/pc/widgets/timeseries/types';
import dayjs from 'dayjs';

const Container = styled.div`
  width: 100%;
  //height: 50px;
  //background-color: #e5e9f3;
  display: flex;
  //padding: 8px 0;
  box-sizing: border-box;
  //flex-shrink: 0;
  align-items: center;
`;
const UiGroup = styled.div`
  display: flex;
  align-items: stretch;
  margin-left: auto;

  .rmdp-mobile {
    position: absolute;
  }

  .rmdp-calendar-container-mobile {
    position: relative !important;
  }

  .date-picker-input {
    background-color: #eaeffa;
    border: 1px solid #c7cede;
    color: #475885;
    width: 300px;
    margin: 0 5px;
    padding: 0 10px;
    height: 32px;
    outline: none;
    text-align: center;
    font-size: 15px;

    &:focus {
      background-color: #ffffff;
    }
  }
`;
const NumInput = styled.input`
  width: 50px;
  text-align: center;
  font-size: 18px;
  background-color: #eaeffa;
  border: 1px solid #c7cede;
  color: #475885;
  box-sizing: border-box;
  outline: none;

  &:focus {
    background-color: #ffffff;
  }
`;
const UnitSelect = styled.select`
  background-color: #eaeffa;
  border: 1px solid #c7cede;
  color: #475885;
  border-left: none;
  font-size: 16px;
  padding: 0 10px;
  outline: none;

  &:focus {
    background-color: #ffffff;
  }
`;
const UpdateTimeDisplay = styled.div`
  font-size: 14px;
  color: #888;
  align-self: center;
  margin-right: 15px;
  flex-shrink: 0;
`;
const SwitchLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #98a9d5;
  margin-left: 20px;

  &.active {
    color: #6287e8;
  }
`;

const dateUnit = ['select', 'minutes', 'hours', 'days', 'months', 'years'] as const;
type NumUnit = (typeof dateUnit)[number];

type IProps = {
  children?: ReactElement | ReactElement[];
  id: string;
  // dragHandle: ReactElement;
  // mode: ChartSizeMode;
  rangeState: ITimestampRangeState;
  lastUpdateTime?: Date;
  direction: 'bottom' | 'top';
  // series: ISeries[];
  goLive(bool: boolean): void;
};

function DateTimeSelectBar({children, id, rangeState, lastUpdateTime, direction, goLive}: IProps): ReactElement {
  const [num, setNum] = useState<number>(1);
  const [numUnit, setNumUnit] = useState<NumUnit>('select');
  const [isLive, setIsLive] = useState(false);
  const [range, setRange] = rangeState;
  // react-multi-date-picker 표시용 별도 range 값
  // const [displayRange, setDisplayRange] = useState<number[]>(range?.map((t) => t * 1000));
  const numRef = useRef<HTMLInputElement>();

  // useEffect(() => {
  //   setDisplayRange(range?.map((t) => t * 1000));
  // }, [range]);

  /**
   * 기간 단위 숫자, select 입력 - 구현부
   * @param num
   * @param numUnit
   */
  const changeRangeUnit = (num: number, numUnit: NumUnit): void => {
    const [, endTimestamp] = range;
    const endDate = new DateObject(endTimestamp * 1000);
    const startDate = new DateObject(endDate).subtract(num, numUnit);
    setRange([startDate.unix, endTimestamp]);
  };

  /**
   * 기간 단위 숫자 입력 - 이벤트 처리부
   * @param e
   */
  const onChangeNum = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;

    if (!value) {
      setNum(0);
    }
    const n = parseInt(value);
    // 숫자로 변환되지 않으면 여기서 중단
    if (isNaN(n)) return;

    setNum(n);
    changeRangeUnit(n, numUnit);
  };

  /**
   * 기간 단위 select 변경 - 이벤트 처리부
   * @param e
   */
  const onChangeNumUnit = (e: ChangeEvent<HTMLSelectElement>): void => {
    const {value} = e.target;
    setNumUnit(value as NumUnit);

    // select 의 경우는 여기서 중단
    if (value === 'select') return;

    changeRangeUnit(num, value as NumUnit);

    setTimeout(() => {
      numRef.current.focus();
    }, 100);
  };

  /**
   * 이전 기간 단위 버튼 킄릭
   */
  const onClickPrev = (): void => {
    const [start] = range;
    const endDate = new DateObject(start * 1000);
    const startDate = new DateObject(endDate).subtract(num, numUnit);
    setRange([startDate.unix, start]);
  };

  /**
   * 다음 기간 단위 버튼 킄릭
   */
  const onClickNext = (): void => {
    const [, end] = range;
    const startDate = new DateObject(end * 1000);
    const endDate = new DateObject(startDate).add(num, numUnit);
    setRange([startDate.unix, endDate.unix]);
  };

  /**
   * DateTime picker 에서 변경
   * @param range
   */
  const onChangeRange = (range: DateObject[]): void => {
    const [start, end] = range;
    if (start && end) {
      console.log(
        'change range\n',
        new DateObject(start.unix * 1000).toDate(),
        '\n',
        new DateObject(end.unix * 1000).toDate()
      );
      // DatePicker 에 적용하고, 다른 객체에도 전달
      setRange([start.unix, end.unix]);
      // select 를 리셋
      setNumUnit('select');
    }
  };

  // const [lastUpdateTime, setLastUpdateTime] = useState<Date>();
  const onChangeLive = (): void => {
    // todo:
    const bool = !isLive;
    setIsLive(bool);
    goLive(bool);
  };

  return (
    <Container>
      {children}
      <UiGroup>
        {isLive ? (
          <UpdateTimeDisplay>
            {lastUpdateTime && (
              <>Last updated {lastUpdateTime && dayjs(lastUpdateTime).format('YYYY-MM-DD HH:mm:ss')}</>
            )}
          </UpdateTimeDisplay>
        ) : (
          <>
            <Button variant="none" disabled={numUnit === 'select'} onClick={onClickPrev}>
              <FontAwesomeIcon icon={faAngleLeft} color="#475885" size="xl" />
            </Button>
            <NumInput
              type="text"
              pattern="\d*"
              maxLength={3}
              ref={numRef}
              value={num}
              disabled={numUnit === 'select'}
              onChange={onChangeNum}
            />
            <UnitSelect value={numUnit} onChange={onChangeNumUnit}>
              {dateUnit.map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </UnitSelect>
            <Button variant="none" disabled={numUnit === 'select'} onClick={onClickNext}>
              <FontAwesomeIcon icon={faAngleRight} color="#475885" size="xl" />
            </Button>
            <DatePicker
              className="rmdp-mobile"
              value={range?.map((t) => t * 1000)}
              range
              inputClass="date-picker-input"
              format="YYYY-MM-DD HH:mm:ss"
              showOtherDays
              numberOfMonths={numUnit === 'months' || numUnit === 'years' ? 2 : 1}
              calendarPosition={direction}
              plugins={[<TimePicker position="bottom" />]}
              onChange={onChangeRange}
            />
          </>
        )}
        <SwitchForm
          name={'live-switch-' + id}
          id={'live-switch-' + id}
          label={<SwitchLabel className={isLive && 'active'}>Live</SwitchLabel>}
          onChange={onChangeLive}
          checked={isLive}
          checkedValue={true}
          uncheckedValue={false}
        />
        {/*<Button width={80} disabled={series.length === 0} onClick={onClickRun}>
          <FontAwesomeIcon icon={faWavePulse} size="xl" />
          Run
        </Button>*/}
      </UiGroup>
    </Container>
  );
}

export default DateTimeSelectBar;
