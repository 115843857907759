import React, {memo, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import styled from 'styled-components';
import {
  WidgetActionPanel,
  WidgetBody,
  WidgetConfigLayer,
  WidgetContainer,
  WidgetHeader
} from 'components/pc/widgets/parts';
import CommodityItem from 'components/pc/widgets/commodity/CommodityItem';
import {ICommodityBasic, ICommodityItem} from 'components/pc/widgets/commodity/types';
import {ISizeType, SizeTypes} from 'components/common/types';
import CommoditySetting from 'components/pc/widgets/commodity/CommoditySetting';
import BasicSpinner from 'components/common/BasicSpinner';
import classNames from 'classnames';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {ICommodityWidgetData, IWidgetNodeData} from 'components/pc/types';
import {defaultList} from 'components/pc/widgets/commodity/const';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import WidgetLiveUpdateStatus from 'components/pc/widgets/parts/WidgetLiveUpdateStatus';
import useLatestNodeHandler from 'hooks/useLatestNodeHandler';
import {INodeInfo} from 'api/data-types';

const Scroller = styled.div`
  height: 100%;
  width: 100%;
  background: #f1f1f1;
`;

const CommodityList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 3.8rem 1em;
  background: #f1f1f1;
  cursor: default;

  &.center {
    height: 100%;
    justify-content: center;
  }

  &.md {
    padding: 0 2.8rem 1em;
  }
  &.sm {
    padding: 0 1.8rem 1em;
  }
`;

const defaultCfg = {
  updateIntervalUnit: 1000 * 60,
  updateIntervalVal: '30',
  autoUpdate: true
};

export type ICommodityCfg = {
  updateIntervalUnit: number;
  updateIntervalVal: string;
  autoUpdate: boolean;
};

const NoCommodityItem = styled.div`
  .tags-icon {
    margin-right: 6px;
  }
`;

const getSizeType = (pixel: number): SizeTypes => {
  if (pixel >= 450 && pixel < 650) {
    return 'md';
  } else if (pixel < 450) {
    return 'sm';
  } else {
    return 'lg';
  }
};

function CommodityWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const {onCanvasChange} = useContext(ProcessCanvasContext);
  // const latestNodeHandler = useLatestTagHandler({type: 'latest_count', latest_count: 2});
  const latestNodeHandler = useLatestNodeHandler({type: 'latest_count', latest_count: 2});
  const [commodityList, setCommodityList] = useState<ICommodityItem[] | null>([] as ICommodityItem[]);
  const [isShowConfig, setIsShowConfig] = useState(false);
  const [widgetSize, setWidgetSize] = useState<ISizeType>({width: 'lg', height: 'lg'});
  const [fontSize] = useState('Font Size');
  const ref = useRef(null);
  const [selectedList, setSelectedList] = useState<ICommodityBasic[]>([] as ICommodityBasic[]);

  const [cfg, setCfg] = useState<ICommodityCfg>(defaultCfg);
  const [time, setTime] = useState<number>(0);

  const [loading, setLoading] = useState(true);

  const {setNodes} = useReactFlow();

  const onClickConfig = (): void => {
    setIsShowConfig(!isShowConfig);
  };

  const align =
    selectedList.length === 0 || (selectedList.length === 0 && commodityList.length !== 0) ? 'center' : 'default';

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        const [target] = entries;
        setWidgetSize({width: getSizeType(target.contentRect.width), height: getSizeType(target.contentRect.height)});
      });
      observer.observe(ref.current);
    }
  }, []);

  useEffect(() => {
    if (data?.metaData) {
      const {selectedList, config} = data.metaData as ICommodityWidgetData;

      setCfg({
        updateIntervalUnit: config?.updateIntervalUnit || 60 * 1000,
        updateIntervalVal: config?.updateIntervalVal || '30',
        autoUpdate: config?.autoUpdate
      });

      if (!config) {
        setCfg(defaultCfg);
      }
      setSelectedList(selectedList);
    } else if (selectedList.length === 0) {
      setLoading(true);
      setSelectedList(defaultList);
    }

    // 초기 metaData 값 설정
    if (!data?.metaData) {
      const metaData = {selectedList: defaultList, config: cfg};
      setNodes((nodes) =>
        nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
      );
      onCanvasChange();
    }
  }, []);

  // data Interval update 코드
  useEffect(() => {
    const nodes: INodeInfo[] = selectedList.map((item) => ({
      database: 'commodity',
      name: item.tag[1],
      unit: item.unit,
      hierarchy: [item.tag[0]]
    }));

    latestNodeHandler.renewSubscribe(id, nodes, cfg.autoUpdate);
  }, [selectedList, cfg.autoUpdate]);

  //res 데이터 부르는 코드
  useEffect(() => {
    const res = latestNodeHandler.latestResult[id];
    if (res) {
      latestNodeHandler.changeDuration(cfg.updateIntervalUnit * Number(cfg.updateIntervalVal));
      const now = Math.max(...(res.map((item) => item?.updateTime) || []));
      setTime(now);

      const merged: ICommodityItem[] = selectedList.map((item) => {
        const keyCheck = `commodity-${item.tag[0]}-${item.tag[1]}`;
        return {
          ...item,
          category: 'commodity',
          data: res.find((i) => i.key === keyCheck)?.value
        };
      });

      setCommodityList(merged);
      setTimeout(() => setLoading(false), 300);

      // setLoading(false);
    }
  }, [latestNodeHandler.latestResult, selectedList]);

  const onChangeLive = () => {
    setCfg((prev) => ({...prev, autoUpdate: !cfg.autoUpdate}));

    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                metaData: {
                  ...node.data.metaData,
                  config: {
                    ...cfg,
                    autoUpdate: !cfg?.autoUpdate
                  }
                }
              }
            }
          : node
      )
    );
  };

  const onChangeCfg = (newCfg: ICommodityCfg, action: string) => {
    switch (action) {
      case 'updateTimer':
        latestNodeHandler.changeDuration(newCfg.updateIntervalUnit * Number(newCfg.updateIntervalVal));
        break;
      default:
    }
    setCfg(newCfg);
  };

  const onConfirm = (list: ICommodityBasic[], newCfg: ICommodityCfg): void => {
    // list나 cfg의 변화가 있다면 if 문 실행. 없다면 else문.
    const hasListChanged = JSON.stringify(list) !== JSON.stringify(selectedList);
    const hasCfgChanged = JSON.stringify(newCfg) !== JSON.stringify(cfg);

    if (hasListChanged || hasCfgChanged) {
      setLoading(true);
      setSelectedList(list);
      const metaData = {
        selectedList: list,
        config: newCfg
      };
      setNodes((nodes) =>
        nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
      );
      onCanvasChange();
    } else {
      setLoading(false);
    }
    setIsShowConfig(!isShowConfig);
  };

  const onCancel = (isShowConfig: boolean): void => {
    setIsShowConfig(false);
  };

  const onClickItem = (item: ICommodityItem) => {
    const refined = selectedList.map((it) => {
      if (it.tag[1] === item.tag[1]) {
        it.isOpen = !it.isOpen;
      }
      return it;
    });
    const metaData = {
      selectedList: refined
    };
    setNodes((nodes) =>
      nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
    );
  };

  return (
    <WidgetContainer {...rest} type="CommodityWidget">
      <WidgetHeader
        {...rest}
        type="CommodityWidget"
        icon={data.icon}
        id={id}
        title={
          data.customizedTitle ? data.title : getWidgetTitle({type: 'CommodityWidget', titleData: selectedList, data})
        }
        suffix="- Commodity"
        onConfig={onClickConfig}
      />
      <WidgetActionPanel align="right">
        <WidgetLiveUpdateStatus
          id={id}
          updateTime={time}
          isLiveUpdate={cfg?.autoUpdate}
          onChangeLiveUpdate={onChangeLive}
        />
      </WidgetActionPanel>
      <WidgetBody ref={ref} actionMenuHeight={60}>
        <Scroller className="thin-scrollbar">
          <CommodityList className={classNames(align, widgetSize.width)}>
            {(selectedList.length === 0 && commodityList.length !== 0) ||
            (selectedList.length === 0 && selectedList !== defaultList) ? (
              <NoCommodityItem>
                <FontAwesomeIcon className="tags-icon" icon={faTags} color="#B6B4C0" size="xl" />
                No selected data
              </NoCommodityItem>
            ) : (
              commodityList.map((item, idx) => (
                <CommodityItem
                  loading={loading}
                  size={widgetSize}
                  fontSize={fontSize}
                  data={item}
                  key={item.tag.join('-')}
                  cfg={cfg}
                  onOpen={() => onClickItem(item)}
                />
              ))
            )}
          </CommodityList>
        </Scroller>
        <BasicSpinner isShow={loading} margin="0 10px" size="lg" type="overlay" position="center-center" />
      </WidgetBody>
      {widgetSize.width !== 'sm' && (
        <WidgetConfigLayer title="Settings" isShow={isShowConfig} onClose={onClickConfig}>
          <CommoditySetting
            id={id}
            defaultList={selectedList}
            cfg={cfg}
            onChangeCfg={onChangeCfg}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        </WidgetConfigLayer>
      )}
    </WidgetContainer>
  );
}
export default memo(CommodityWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
