import {
  BlockTableWidget,
  CommodityWidget,
  DatasheetLocalDbWidget,
  DataSheetWidget,
  HmbRevisionWidget,
  HmbWidget,
  ImageWidget,
  JmpWidget,
  LineDetectionWidget,
  MetaPfdWidget,
  PythonEditorWidget,
  SampleWidget,
  ShapeNode,
  SpreadsheetWidget,
  StickyNoteWidget,
  ThreeLandscapeWidget,
  ThreeWidget,
  TimeSeriesWidget,
  WeatherWidget,
  WebWidget,
  YoutubeWidget
} from 'components/pc/widgets/index';
import PythonEditorLogWidget from 'components/pc/widgets/pythonEditor/PythonEditorLogWidget';
import PythonEditorSimulationResultWidget from 'components/pc/widgets/pythonEditor/PythonEditorSimulationResultWidget';

export const widgetCollection = {
  BlockTableWidget,
  CommodityWidget,
  DataSheetWidget: DatasheetLocalDbWidget,
  DatasheetLocalDbWidget,
  HmbRevisionWidget,
  HmbWidget: HmbRevisionWidget,
  ImageWidget,
  JmpWidget,
  LineDetectionWidget,
  MetaPfdWidget,
  PythonEditorWidget,
  SampleWidget,
  SpreadsheetWidget,
  StickyNoteWidget,
  ThreeLandscapeWidget,
  ThreeWidget,
  TimeSeriesWidget,
  WeatherWidget,
  WebWidget,
  YoutubeWidget,
  shape: ShapeNode,
  PythonEditorLogWidget,
  PythonEditorSimulationResultWidget
};
