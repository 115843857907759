import {PropsWithChildren, ReactElement} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Container = styled.span`
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 2px;
  line-height: 1em;

  &.owner {
    text-shadow: 1px 1px 1px 1px black;
    background-color: ${({theme}) => theme.color.owner};
  }

  &.public {
    background-color: ${({theme}) => theme.color.public};
  }
`;

type IProps = PropsWithChildren & {
  type: 'owner' | 'public';
  className?: string;
};

function Badge({type, children, className}: IProps): ReactElement {
  return <Container className={classNames(type, className)}>{children}</Container>;
}

export default Badge;
