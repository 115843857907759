import {ChangeEvent, ReactNode, useContext} from 'react';
import styled from 'styled-components';
import {Button, SelectForm} from 'components/forms';
import {colors, SensorType} from 'components/mpfd/panel/const';
import PropertyRow from 'components/mpfd/panel/parts/property/PropertyRow';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {IAnnotation} from 'components/mpfd/type';
import classnames from 'classnames';

const ColorPicker = styled.div`
  display: flex;
  gap: 6px;
  margin: 6px;
`;

const ButtonWrapper = styled.div<{$color: string}>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) => props.$color};

  &.active {
    box-shadow: 0 0 1px 5px ${(props) => `${props.$color}88`}; // 오타 아님
  }
`;

type IProps = {
  selectedAnnot: IAnnotation;
  children: ReactNode;
  onClickAddTagBtn(): void;
};

function StreamProperty({selectedAnnot, onClickAddTagBtn, children}: IProps) {
  const {color} = selectedAnnot;
  const {dispatchToReducer} = useContext(MetaPfdContext);

  const onChangeSensorType = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    dispatchToReducer({type: 'CHANGE_REGION', region: {...selectedAnnot, cls: target.value}});
  };

  const onChangeColor = (color: string) => {
    dispatchToReducer({type: 'CHANGE_REGION', region: {...selectedAnnot, color}});
  };

  return (
    <>
      <PropertyRow rowHeader="Name">{children}</PropertyRow>
      <PropertyRow rowHeader="Sensor type">
        <SelectForm name="Sensor type" options={SensorType} onChange={onChangeSensorType} value={selectedAnnot?.cls} />
      </PropertyRow>
      <PropertyRow rowHeader="Color">
        <ColorPicker>
          {colors.map((item) => (
            <ButtonWrapper
              key={item}
              $color={item}
              onClick={() => onChangeColor(item)}
              className={classnames('shape-color', color === item && 'active')}
            />
          ))}
        </ColorPicker>
      </PropertyRow>
      <PropertyRow>
        <Button variant="info" width="100%" height={30} onClick={onClickAddTagBtn}>
          Select Tag
        </Button>
      </PropertyRow>
    </>
  );
}
export default StreamProperty;
