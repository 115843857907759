import {memo, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {NodeProps, NodeResizer} from 'reactflow';
import {WidgetHeader} from 'components/pc/widgets/parts';
import {getWidgetTitle, pcFunc} from 'utils/processCanvas-functions';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {ContactShadows, Environment, Grid, OrbitControls} from '@react-three/drei';
import ModelShells from 'components/pc/widgets/three/renderer/block/Model_Shells';
import {Canvas} from '@react-three/fiber';
import {LeftMenu} from 'components/pc/widgets/three/renderer/ui';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import ThreeLandscapeWidgetContainer from 'components/pc/widgets/three/renderer/label/ThreeLandscapeWidgetContainer';
import ThreeCanvasWrapper from 'components/pc/widgets/three/ThreeCanvasWrapper';
import Pipes from 'components/pc/widgets/three/Pipes';
import ThreeLandscapeLabelsWrapper from 'components/pc/widgets/three/renderer/label/ThreeLandscapeLabelsWrapper';
import Blocks from 'components/pc/widgets/three/Blocks';
import {
  canvasConfig,
  controlsConfig,
  gltfModelsPaths,
  gridConfig,
  shadowConfig
} from 'components/pc/widgets/three/constants/config';
import {getApiHost} from 'api/function';
import WhiteBackground from 'components/pc/widgets/three/WhiteBackground';
import {Vector3Like} from 'three';
import * as THREE from 'three';
import {Camera} from '@react-three/fiber';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {useReactFlow} from 'reactflow';
import {getUniqueKey} from 'utils/commons';
import useApi from 'api/useApi';
import {IBlockTableData} from 'components/spreadsheet/spreadsheet-adapter';
import {faTable} from '@fortawesome/pro-light-svg-icons';
import {getBlockValues, parseCheckedListData} from 'components/pc/widgets/blockTable/block-functions';
import {DataContext} from 'api/DataProvider';

export type ISelectedLabelInfo = {
  labelName: string;
  labelType: 'streams' | 'blocks' | 'undefined';
  labelPosition: {
    x: number;
    y: number;
    z: number;
  };
  leftMenuClicked?: boolean;
  clickedPosition?: {
    // 클릭으로 label이 선택되었을 경우 캔버스에서의 x, y 위치를 저장
    x: number;
    y: number;
  };
};

export type IManualCamera = Camera & {
  manual?: boolean;
};

function ThreeLandscapeWidget({data, id, ...rest}: NodeProps): ReactElement {
  const {isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;
  const [isLoading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isTransparent, updateTransparent] = useState(true);
  const [isWire, updateWire] = useState(false);
  const [labelsVisible, updateLabels] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState<ISelectedLabelInfo>(null);
  const [isShowNodeSelector, setIsShowNodeSelector] = useState<boolean>(false);
  const [selectedBlockNodes, setSelectedBlockNodes] = useState<string[][]>([]);
  const reactFlow = useReactFlow();
  const api = useApi();
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;

  const [blockVisibility, setBlockVisibility] = useState<any>({
    '01': true,
    '02': true,
    '03': true,
    '04': true,
    '05': true,
    '06': true,
    '07': true
  });

  const [streamVisibility, setStreamVisibility] = useState<any>({
    '01': true,
    '02': true,
    '03': true
  });

  const containerRef = useRef(null);
  const controlsRef = useRef(null);

  useEffect(() => {
    const API_HOST = getApiHost();
    const loader = new GLTFLoader();
    const loadModelPromises = gltfModelsPaths.map((pathForGltf) => {
      return loader
        .loadAsync(API_HOST + pathForGltf)
        .then((response) => {
          response.parser.json.meshes.map((meshGroup) => {
            setLoadingMessage(`Loading ${meshGroup.name}...`);
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    });

    Promise.all(loadModelPromises).then(() => {
      setLoading(false);
    });
  }, []);

  const onToggleBlockVisibility = (buttonName: string) => {
    setBlockVisibility((prevBlockVisibility) => {
      const areAllButtonsPressed = Object.values(prevBlockVisibility).every((value) => value === true);
      const updatedBlockVisibility = {};
      if (areAllButtonsPressed) {
        Object.keys(prevBlockVisibility).forEach((key) => {
          updatedBlockVisibility[key] = key === buttonName;
        });
      } else {
        Object.keys(prevBlockVisibility).forEach((key) => {
          updatedBlockVisibility[key] = key === buttonName ? !prevBlockVisibility[key] : prevBlockVisibility[key];
        });
      }
      return updatedBlockVisibility;
    });
  };

  const onToggleStreamVisibility = (buttonName: string) => {
    setStreamVisibility((prevStreamVisibility) => {
      const areAllButtonsPressed = Object.values(prevStreamVisibility).every((value) => value === true);
      const updatedStreamVisibility = {};
      if (areAllButtonsPressed) {
        Object.keys(prevStreamVisibility).forEach((key) => {
          updatedStreamVisibility[key] = key === buttonName;
        });
      } else {
        Object.keys(prevStreamVisibility).forEach((key) => {
          updatedStreamVisibility[key] = key === buttonName ? !prevStreamVisibility[key] : prevStreamVisibility[key];
        });
      }
      return updatedStreamVisibility;
    });
  };

  const modelScale = 0.2;

  const moveCamera = (
    position: Vector3Like,
    camera: IManualCamera,
    scene: THREE.Scene,
    targetSize?: 'big' | 'small'
  ) => {
    if (targetSize === null) targetSize = 'small';

    // factors that decide the camera position: distance, elevationFactor, modelScale
    let distance = 40;
    let elevationFactor = 30;
    switch (targetSize) {
      case 'big':
        distance = 80;
        elevationFactor = 60;
        break;
      case 'small':
        distance = 1;
        elevationFactor = 25;
        break;
    }

    // Calculate the direction from the camera to the mesh
    const direction = new THREE.Vector3().subVectors(position, camera.position).normalize();

    // Final position for the camera
    const finalPosition = new THREE.Vector3()
      .addVectors(position, direction.multiplyScalar(-distance))
      .multiplyScalar(modelScale);
    finalPosition.y += elevationFactor * modelScale;

    // Check for occlusion and adjust camera rotation if necessary
    const raycaster = new THREE.Raycaster(camera.position, direction);
    const intersects = raycaster.intersectObjects(scene.children, true);

    let occlusionDetected = false;
    for (let i = 0; i < intersects.length; i++) {
      // if (intersects[i].object !== mesh) {
      //   occlusionDetected = true;
      //   break;
      // }
    }

    if (occlusionDetected) {
      // Rotate the camera 45 degrees around the Y-axis of the mesh
      const yAxis = new THREE.Vector3(0, 1, 0);
      const angle = 45 * (Math.PI / 180); // Converting degrees to radians

      // Calculate the new camera position after rotation
      const rotatedPosition = new THREE.Vector3().subVectors(camera.position, position);
      rotatedPosition.applyAxisAngle(yAxis, angle);
      rotatedPosition.add(position);

      // Update the final position to the new rotated position
      finalPosition.copy(rotatedPosition);
    }

    // Animate camera movement
    const duration = 1000;
    const startTime = Date.now();

    function animate() {
      const elapsed = Date.now() - startTime;
      const fraction = elapsed / duration;

      if (fraction < 1) {
        // Interpolate camera position
        camera.position.lerpVectors(camera.position, finalPosition, fraction);

        // Update OrbitControls target
        if (controlsRef && controlsRef.current) {
          const currentTarget = controlsRef.current.target;
          const newTarget = new THREE.Vector3(
            position.x * modelScale,
            position.y * modelScale,
            position.z * modelScale
          );
          currentTarget.lerpVectors(currentTarget, newTarget, fraction);
          controlsRef.current.update();
        }

        requestAnimationFrame(animate);
      } else {
        // Finalize camera position
        camera.position.copy(finalPosition);

        if (controlsRef && controlsRef.current) {
          controlsRef.current.target.set(position.x * modelScale, position.y * modelScale, position.z * modelScale);
          controlsRef.current.update();
        }
      }
    }
    animate();
  };

  const onClickResetView = () => {
    if (controlsRef && controlsRef.current) {
      setSelectedLabel(null);

      const controls = controlsRef.current;
      const {fov, position} = canvasConfig.camera;
      controls.object.fov = fov;
      controls.object.position.set(position[0], position[1], position[2]);

      const defaultTarget = new THREE.Vector3(0, 0, 0); // Replace with your default target position
      controls.target.copy(defaultTarget);

      controls.update();
    }
  };

  const onSelectBlockNode = async (checkedList: string[]) => {
    if (checkedList && checkedList.length > 0) {
      const parsed = parseCheckedListData(checkedList);
      setSelectedBlockNodes(parsed);
      const valueAddedParsed = await getBlockValues(
        parsed,
        api,
        selectedLabel?.labelName,
        globalSettings?.significantDigit || 4
      );
      openBlockDatasheet(valueAddedParsed, parsed);
    }
  };

  const openBlockDatasheet = (tableData: string[][], selectedNodes: string[][]) => {
    const newDatasheetId = getUniqueKey();
    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());
    const metaData: IBlockTableData = {
      colHeaders: false,
      rowHeaders: false,
      colWidth: 200,
      data: tableData,
      selectedBlockNodes: selectedNodes,
      targetBlockName: selectedLabel?.labelName,
      rendererType: 'HierarchyDataRenderer',
      initialized: true
    };
    reactFlow.addNodes({
      id: newDatasheetId,
      type: 'BlockTableWidget',
      data: {
        title: selectedLabel?.labelName || 'Undefined Label',
        customizedTitle: false,
        icon: faTable,
        metaData: metaData
      },
      style: {
        width: 600,
        height: 500,
        minWidth: 200,
        minHeight: 200
      },
      zIndex: highestZIndex,
      position: {
        x: selectedLabel?.clickedPosition.x + 50 || 400,
        y: selectedLabel?.clickedPosition.y + 50 || 50
      }
    });
  };

  return (
    <>
      <ThreeLandscapeWidgetContainer isNodeSelectorOpen={isShowNodeSelector} isFrozen={isFreeze}>
        <WidgetHeader
          {...rest}
          type="ThreeLandscapeWidget"
          icon={data.icon}
          title={
            data.customizedTitle ? data.title : getWidgetTitle({type: 'ThreeLandscapeWidget', titleData: '', data})
          }
          suffix="- 3D Landscape"
          id={id}
        />
        <ThreeCanvasWrapper ref={containerRef} isLoading={isLoading} loadingMessage={loadingMessage}>
          <Canvas
            {...canvasConfig}
            style={{
              height: '100%',
              overflow: 'visible',
              background: '#ffffff',
              pointerEvents: isShowNodeSelector || isFreeze ? 'none' : 'auto'
            }}
          >
            <WhiteBackground />
            <ambientLight intensity={Math.PI} />
            <Environment preset="city" blur={0.5} />
            <OrbitControls ref={controlsRef} domElement={containerRef?.current} {...controlsConfig} />
            <ContactShadows {...shadowConfig} />
            <Grid {...gridConfig} />
            <ModelShells
              modelScale={modelScale}
              transparent={isTransparent}
              wireframes={isWire}
              blockVisibility={blockVisibility}
              moveCamera={moveCamera}
              setSelectedLabel={setSelectedLabel}
            />
            <ThreeLandscapeLabelsWrapper
              labelsVisible={labelsVisible}
              moveCamera={moveCamera}
              setIsShowNodeSelector={setIsShowNodeSelector}
              selectedLabel={selectedLabel}
              setSelectedLabel={setSelectedLabel}
            />
            <Blocks blockVisibility={blockVisibility} />
            <Pipes streamVisibility={streamVisibility} />
          </Canvas>
        </ThreeCanvasWrapper>
        <LeftMenu
          blockVisibility={blockVisibility}
          streamVisibility={streamVisibility}
          // onToggleBlock={onToggleBlockVisibility}
          // onToggleStream={onToggleStreamVisibility}
          isShowWireFrame={isWire}
          isShowLabel={labelsVisible}
          onClickResetView={onClickResetView}
          onToggleWireFrame={() => updateWire(!isWire)}
          onToggleShowLabel={() => updateLabels(!labelsVisible)}
          selectedLabel={selectedLabel}
          setSelectedLabel={setSelectedLabel}
        />
        {/*<Footer
          transparent={isTransparent}
          wireframes={isWire}
          labels={labelsVisible}
          updateTransparent={updateTransparent}
          updateWire={updateWire}
          updateLabels={updateLabels}
        />*/}
      </ThreeLandscapeWidgetContainer>
      {isShowNodeSelector && (
        <NodeSelectorRevision
          title="Select Nodes"
          selectedNodes={selectedBlockNodes}
          onClose={() => {
            setIsShowNodeSelector(false);
            setSelectedBlockNodes(null);
          }}
          options={{
            searchStrings: [selectedLabel?.labelName]
          }}
          onSelect={onSelectBlockNode}
          checkBoxTreeOptions={{
            onlyLeafCheckboxes: false,
            expandUntilSearchString: true
          }}
        />
      )}
      <NodeResizer color="#ff0071" isVisible={rest.selected && !isFreeze} />
    </>
  );
}
export default memo(ThreeLandscapeWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
