import {Context, createContext, PropsWithChildren, useEffect, useState} from 'react';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {getApiHost} from 'api/function';
import {IApiReturnBasic} from 'api/data-types';
import packageInfo from '../../../package.json';

type IUserProfileListReturn = IApiReturnBasic & {
  data?: {
    user_list: IUserProfile[];
  };
};

export type IUserProfile = {
  email: string;
  firstName: string;
  groups: [];
  is_active: boolean;
  lastName: string;
  roles: [];
  username: string;
};

export type IAuthContext = {
  userProfile: IUserProfile;
  userInfoList: IUserProfile[];
  ownerName: string;
  isLoading: boolean;
  getUserInfo(): Promise<IUserProfile | null>;
  getUserInfoList(): Promise<IUserProfileListReturn | null>;
  setOwnerName: (ownerName: string) => void;
};

type IReturnGetUserInfo = {
  user_info: IUserProfile;
};

const API_HOST = getApiHost();

export const AuthContext: Context<IAuthContext> = createContext(null);

function AuthProvider({children}: PropsWithChildren) {
  const [userProfile, setUserProfile] = useState<IUserProfile>(null);
  const [userInfoList, setUserInfoList] = useState<IUserProfile[]>([]);
  const [ownerName, setOwnerName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;

  // userInfo api 가져오는 코드
  const getUserInfo = async () => {
    setIsLoading(true);
    // const API_HOST = process.env.REACT_APP_API_HOST;
    const userInfo = await fetch(API_HOST + '/auth/get_user_info', {
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`}
    })
      .then((response) => {
        return response.json();
      })
      .then((r) => {
        const res = r as IApiReturnBasic;
        if (res.success) {
          const data = res.data as IReturnGetUserInfo;
          return data.user_info as IUserProfile;
        }
      });
    setUserProfile(userInfo);
    setIsLoading(false);
    return userInfo || null;
  };

  // user info list를 가져오는 코드
  const getUserInfoList = async () => {
    const userInfoList = await fetch(API_HOST + '/auth/get_user_list', {
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`}
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log(res);
        return res;
      });
    setUserInfoList(userInfoList?.data?.user_list);
    return userInfoList || null;
  };

  const collection = {
    userProfile,
    userInfoList,
    ownerName,
    isLoading,
    getUserInfo,
    getUserInfoList,
    setOwnerName
  };

  // 초기 렌더링 시 token이 있을 경우 userInfo를 가져온다
  useEffect(() => {
    if (token) {
      getUserInfo();
    }
  }, [token]);

  /**
   * 버전이 다를 경우 - 이전버전에 있던 token 을 삭제한 후 리로드
   */
  useEffect(() => {
    const version = packageInfo?.version;
    const prevVersion = LocalStorageManager.getItem('CLIENT_VERSION');
    if (version !== prevVersion) {
      LocalStorageManager.removeItem('PROCESSMETAVERSE_LOGIN_TOKEN');
      LocalStorageManager.setItem('CLIENT_VERSION', version);
      window.location.reload();
    }
  }, []);

  return <AuthContext.Provider value={collection}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
