import React, {ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, CheckboxForm, TextForm} from 'components/forms';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {useNavigate} from 'react-router-dom';
import {useReactFlow} from 'reactflow';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import FormRow from 'components/forms/FormRow';

const ValidMessage = styled.p`
  color: ${({theme}) => theme.color.invalid};
`;

export type ISaveFile = {
  title: string;
  fileName: string;
  isSaveAs: boolean;
};

function ProcessCanvasSaveAsModal(): ReactElement {
  const {saveAsModalState, saveLoadingModalState, save, getFileList, isPublic, setIsPublic, isRename, rename} =
    useContext(ProcessCanvasContext);
  const reactFlow = useReactFlow();
  const [saveFileModalData, setSaveFileModalData] = saveAsModalState;
  const [, setIsShowSaveLoadingModal] = saveLoadingModalState;
  const [text, setText] = useState(saveFileModalData?.fileName || '');
  const [validMessage, setValidMessage] = useState('');
  const navigate = useNavigate();

  useModalKeyBoardEvent({
    onConfirm: () => (isRename === false ? onClickConfirm() : onClickRename()),
    onCancel: () => onClose()
  });
  useEffect(() => {
    if (saveFileModalData) {
      setText(saveFileModalData?.fileName || '');
      setIsPublic(false);
    }
    return () => {
      setValidMessage('');
    };
  }, [saveFileModalData]);

  const onClose = (): void => {
    setSaveFileModalData(undefined);
  };

  const onChangePublic = () => {
    setIsPublic(!isPublic);
  };

  const onChangeText = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    setText(value);
    setValidMessage(undefined);
  };

  const onClickConfirm = (): void => {
    if (!text?.trim()) {
      setValidMessage('Please input file name.');
      return;
    } else {
      setIsShowSaveLoadingModal(true);
      save(text, reactFlow.getNodes(), reactFlow.getEdges(), Boolean(saveFileModalData?.isSaveAs)).then((fileData) => {
        setSaveFileModalData(undefined);
        getFileList().then(() => {
          navigate(`/pc/${fileData._id}`);
        });
      });
    }
  };

  const onClickRename = (): void => {
    setIsShowSaveLoadingModal(true);
    rename(text).then(() => {
      setSaveFileModalData(undefined);
      getFileList().then();
    });
  };

  if (saveFileModalData) {
    return (
      <ModalWrapper type="sm">
        <ModalHeader onClose={onClose}>{saveFileModalData.title}</ModalHeader>
        <ModalBody>
          <TextForm autoFocus value={text} width={400} placeholder="Input file name" onChange={onChangeText} />
          {!isRename && (
            <FormRow header="Save this file as a public file" headerWidth={60}>
              <CheckboxForm align="center" name="public" checked={isPublic} onChange={onChangePublic} label="Public" />
            </FormRow>
          )}
          <ValidMessage>{validMessage}</ValidMessage>
        </ModalBody>
        <ModalFooter>
          <Button width={100} onClick={isRename ? onClickRename : onClickConfirm}>
            Save
          </Button>
          <Button variant="cancel" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalWrapper>
    );
  } else {
    return undefined;
  }
}

export default ProcessCanvasSaveAsModal;
