import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {ICopilotSettings} from 'components/menu/copilot/CopilotSettingModal';
import {LocalStorageManager} from 'utils/local-storage-manager';
import styled from 'styled-components';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import {ICopilotNodeDataParams} from 'components/menu/copilot/types';
import {getUniqueKey} from 'utils/commons';

const SwitchWrap = styled.div``;

const DatePickerWrap = styled.div`
  flex: 1;

  .date-picker-input {
    background-color: #eaeffa;
    //background-color: #ffffff;
    border: 1px solid #c7cede;
    color: #475885;
    width: 375px;
    margin: 0 5px;
    padding: 0 10px;
    height: 32px;
    outline: none;
    text-align: center;
    font-size: 15px;

    &:focus {
      //background-color: #ffffff;
      background-color: #eaeffa;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;

const SelectNodeBtnWrap = styled.div`
  //margin-bottom: 15px;
  //width: 100%;
  //display: flex;
  //align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: stretch;

  .rmdp-calendar-container-mobile {
    position: relative !important;
    //left: auto !important;
    //top: auto !important;
  }

  .select-node-btn {
    width: 27.5%;
    //width: 38.5%;
    flex-shrink: 0;
  }
  svg {
    margin-right: 5px;
  }

  .rmdp-mobile {
    position: absolute;
  }
`;

const NodeListContainer = styled.div`
  display: flex;
  max-height: 400px;
  min-height: 400px;
  flex: 1;
  //border: 1px solid #171523;
  border: 1px solid #8696b9;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  //height: 30px;
  width: 100%;
  //max-height: 30px;
  background-color: #5556a9;
  color: #b0b1ea;
  white-space: nowrap;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
`;

const SelectNodeList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 290px;
  //overflow-y: auto;
  overflow: auto;

  &.center {
    align-items: center;
    justify-content: center;
    height: 370px;
    svg {
      margin-right: 5px;
    }
  }
`;

const SelectNodeItem = styled.div`
  display: flex;
  padding: 8px 14px;
  //border-top: 1px solid #171523;
  border-top: 1px solid #8696b9;
`;

export type ICopilotNewChatSettings = {
  dataList: string[];
};

// data range 형태 변환 함수
export const generateMonthlyArray = (startPoint: number, endPoint: number) => {
  const startYear = Math.floor(startPoint / 100);
  const startMonth = startPoint % 100;
  const endYear = Math.floor(endPoint / 100);
  const endMonth = endPoint % 100;

  const result = [];

  for (let year = startYear; year <= endYear; year++) {
    const start = year === startYear ? startMonth : 1;
    const end = year === endYear ? endMonth : 12;

    for (let month = start; month <= end; month++) {
      result.push(`${year}${String(month).padStart(2, '0')}`);
    }
  }
  return result;
};

const dateToString = (startPoint: number, endPoint: number): [string, string] => {
  const startString = String(startPoint);
  const endString = String(endPoint);
  return [startString, endString];
};

const defaultSettings: ICopilotSettings = {dataIsOn: false};

const dateUnit = ['days', 'months', 'years'] as const;
type NumUnit = (typeof dateUnit)[number];

type IProps = {
  onClose(): void;
};

function NewChatModal({onClose}: IProps) {
  const {setIsOn, setDataList, isWaiting, changeDataRange, dataList, dataRange} = useContext(CopilotContext);

  const savedSettings = LocalStorageManager.getItem<ICopilotSettings>('COPILOT_SETTINGS') || defaultSettings;
  const [settings, setSettings] = useState<ICopilotSettings>(savedSettings);
  const [selectedStartDate, setSelectedStartDate] = useState<number>();
  const [selectedEndDate, setSelectedEndDate] = useState<number>();

  const [selectedNodes, setSelectedNodes] = useState<string[][]>([]);
  const [isShowNodeSelector, setIsShowNodeSelector] = useState(false);

  const now = new DateObject();
  const end = now.unix;
  const start = now.subtract(7, 'day').unix;
  const rangeState = useState<number[]>([start, end]);
  const [range, setRange] = rangeState;

  useEffect(() => {
    setDataList(dateToString(selectedStartDate, selectedEndDate));
  }, [selectedStartDate, selectedEndDate]);

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  const onConfirm = () => {
    LocalStorageManager.setItem('COPILOT_SETTINGS_NEWCHAT', settings);
    // setIsOn(settings.dataIsOn);
    setDataList(dataList);

    if (isWaiting === false) {
      changeDataRange(dataInfo); // todo : ()안에 인자 값으로 start select date 전달
    }
    /*    const r = rawData as unknown;
    console.log(JSON.parse(r as string));*/ // todo: rawData json.parse 처리 필요
    onClose();
  };

  /*const onToggle = (name: string, value: boolean) => {
    const changed = {
      ...settings,
      [name]: value // 동적 key 할당
    };
    setSettings(changed);
  };*/

  const startRange = parseFloat(dataRange?.[0]);
  const endRange = parseFloat(dataRange?.[1]);

  //todo: 202401을 available_data_ranged return 배열[0]으로 변경

  //todo: selectedStartDate가 selectedEndDate보다 큰 경우는 conFirm을 막거나 선택지 변화 주기
  const dateOptions = generateMonthlyArray(startRange, endRange).map((date) => ({
    value: date,
    label: `${date.slice(0, 4)}-${date.slice(4)}`
  }));

  useEffect(() => {
    if (dateOptions.length > 0 && selectedStartDate === undefined && selectedEndDate === undefined) {
      setSelectedStartDate(Number(dateOptions[0].value));
      setSelectedEndDate(Number(dateOptions[dateOptions.length - 1].value));
    }
  }, [dateOptions, selectedStartDate, selectedEndDate]);

  const onChangeStartDateType = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedStartDate(Number(e.target.value));
  };

  const onChangeEndDateType = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedEndDate(Number(e.target.value));
  };

  // const disabled = settings.dataIsOn === false ? true : false;

  const startOptions = dateOptions.filter((option) => Number(option.value) <= selectedEndDate);
  const endOptions = dateOptions.filter((option) => Number(option.value) >= selectedStartDate);

  const onChangeRange = (range: DateObject[]): void => {
    const [start, end] = range;
    if (start && end) {
      // DatePicker 에 적용하고, 다른 객체에도 전달
      setRange([start.unix, end.unix]);
    }
  };

  const onSelectNode = (checkedList: string[]): void => {
    const parsed = checkedList.map((item) => JSON.parse(item));
    setSelectedNodes(parsed);
  };

  const dataInfo: ICopilotNodeDataParams = {
    time_range: range,
    node_infos: selectedNodes.map((nodes) => ({
      node: nodes.slice(1),
      database: nodes[0]
    }))
  };

  const noSelectNodes = selectedNodes.length === 0 ? 'center' : '';

  return (
    <ModalWrapper type="md">
      <ModalHeader onClose={onClose}>New Chat Settings</ModalHeader>
      <ModalBody align="top" size="full-width">
        <SelectNodeBtnWrap>
          <Button className="select-node-btn" variant="dark" height={34} onClick={() => setIsShowNodeSelector(true)}>
            <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
            <span>Select Nodes</span>
          </Button>
          <DatePickerWrap>
            <DatePicker
              className="rmdp-mobile"
              value={range?.map((t) => t * 1000)}
              range
              inputClass="date-picker-input"
              format="YYYY-MM-DD"
              showOtherDays
              disabled={selectedNodes.length === 0}
              onChange={onChangeRange}
            />
          </DatePickerWrap>
        </SelectNodeBtnWrap>
        <NodeListContainer>
          <Header>Selected Node List</Header>
          <SelectNodeList className={classNames('thin-scrollbar md', noSelectNodes)}>
            {selectedNodes.map((item) => (
              <SelectNodeItem key={getUniqueKey()}>{item.join('.')}</SelectNodeItem>
            ))}
            {selectedNodes.length === 0 && (
              <span>
                <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
                No Selected Nodes
              </span>
            )}
          </SelectNodeList>
        </NodeListContainer>

        {isShowNodeSelector && (
          <NodeSelectorRevision
            title="Select Nodes"
            selectedNodes={selectedNodes}
            onClose={() => setIsShowNodeSelector(false)}
            onSelect={onSelectNode}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button width={150} onClick={onConfirm}>
          Create new chat
        </Button>
        <Button variant="cancel" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default NewChatModal;
