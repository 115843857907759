import React, {ReactElement, RefObject, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

type ContainerStyleProps = {
  $left?: number;
  $top?: number;
  $width?: number;
  $height?: number;
  $isLiveUpdate?: boolean;
  $disabled?: boolean;
  $zoom?: number;
  $isChanging?: boolean;
  $smoothCss?: boolean;
};

const Container = styled.div<ContainerStyleProps>`
  left: ${(props) => props.$left}px;
  top: ${(props) => props.$top}px;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  overflow: hidden;
  position: absolute;
  z-index: 0;
  pointer-events: all;
  display: ${(props) => (props.$isChanging ? 'none' : 'default')};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: ${(props) => (props.$zoom ? `scale(${props.$zoom})` : '')};
  transform-origin: left top;
`;

const ResizeIconContainer = styled.div<ContainerStyleProps>`
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  right: 1px;
  bottom: 1px;
  border-radius: 0 0 10px 0;
  cursor: ${(props) => (props.$disabled ? 'default' : 'nwse-resize')};
  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

type IProps<T> = {
  left: number;
  top: number;
  width: number;
  height: number;
  scale?: number;
  relativeRefObject?: RefObject<T>;
  id?: string;
  disabled: boolean;
  children: ReactElement | ReactElement[];
  minHeight?: number;
  maxHeight?: number;
  minWidth?: number;
  smoothCss?: boolean;
  onDragStart?(): void;
  afterDragEnd?(left: number, top: number, containerRef: RefObject<HTMLDivElement>): void;
  afterResizeEnd?(w: number, h: number, containerRef: RefObject<HTMLDivElement>): void;
};

function ResizableFrame<T>({
  left,
  top,
  width,
  height,
  scale,
  relativeRefObject,
  id,
  disabled,
  minHeight = 10,
  minWidth = 10,
  maxHeight = 9999,
  children,
  smoothCss,
  onDragStart,
  afterDragEnd,
  afterResizeEnd
}: IProps<T>) {
  const [boundingRect, setBoundingRect] = useState({width, height, left, top});
  const [isChanging] = useState(false);
  const containerRef = useRef(null);
  const boundingRectRef = useRef(null);
  boundingRectRef.current = boundingRect;

  useEffect(() => {
    setBoundingRect({left, top, width, height});
  }, [left, top, width, height]);

  const onMouseDownHandle = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (disabled || e.button !== 0) {
      return;
    }
    onDragStart?.();
    const startX = e.clientX;
    const startY = e.clientY;
    const initialLeft = boundingRect.left;
    const initialTop = boundingRect.top;

    const onMouseMove = (e: MouseEvent): void => {
      const dx = e.clientX - startX;
      const dy = e.clientY - startY;
      setBoundingRect((prev) => ({...prev, left: initialLeft + dx, top: initialTop + dy}));
    };

    const onMouseUp = () => {
      afterDragEnd?.(boundingRectRef?.current?.left, boundingRectRef?.current?.top, containerRef);
      document.removeEventListener('mousemove', onMouseMove);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp, {once: true});
  };

  const onMouseDownResizer = (e: React.MouseEvent) => {
    if (disabled || e.button !== 0) {
      return;
    }
    e.stopPropagation();
    const startX = e.clientX;
    const startY = e.clientY;
    const initialWidth = boundingRect.width;
    const initialHeight = boundingRect.height;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const dx = (moveEvent.clientX - startX) / scale;
      const dy = (moveEvent.clientY - startY) / scale;
      const newHeight = Math.min(initialHeight + dy, maxHeight);
      setBoundingRect((prev) => ({
        ...prev,
        width: Math.max(minWidth, initialWidth + dx),
        height: Math.max(minHeight, newHeight)
      }));
    };

    const onMouseUp = () => {
      afterResizeEnd?.(boundingRectRef?.current?.width * scale, boundingRectRef?.current?.height * scale, containerRef);
      document.removeEventListener('mousemove', onMouseMove);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp, {once: true});
  };

  if (isNaN(boundingRect.left) || isNaN(boundingRect.top) || isNaN(boundingRect.width) || isNaN(boundingRect.height))
    return null;
  return (
    <Container
      $left={boundingRect.left}
      $top={boundingRect.top}
      $width={boundingRect.width}
      $height={boundingRect.height}
      $disabled={disabled}
      $zoom={scale}
      $isChanging={isChanging}
      $smoothCss={smoothCss}
      onWheel={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseDown={onMouseDownHandle}
      ref={containerRef}
    >
      {children}
      <ResizeIconContainer $disabled={disabled} onMouseDown={onMouseDownResizer}>
        <svg width="64px" height="64px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M10 20L20 20L20 10" stroke="#CCCCCC" strokeWidth="2"></path>
            <path d="M12 17L17 17L17 12" stroke="#CCCCCC" strokeWidth="2"></path>
          </g>
        </svg>
      </ResizeIconContainer>
    </Container>
  );
}

export default ResizableFrame;
