import {Tooltip as ReactTooltip, PlacesType, TooltipRefProps} from 'react-tooltip';
import React, {PropsWithChildren, ReactElement, useContext, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {getUniqueKey} from 'utils/commons';
import {CommonContext} from 'components/common/CommonProvider';

const tooltipStyle = {
  color: '#575660',
  backgroundColor: '#fff',
  zIndex: 100,
  lineHeight: '2'
};
const TooltipTrigger = styled.div`
  display: flex;
  justify-content: center;
`;
const borderStyle = '1px solid #575660';

type IProps = PropsWithChildren & {
  type?: 'default' | 'guide' | 'browser-permission' | 'button' | 'notice';
  isOpen?: boolean;
  content?: string | boolean | ReactElement;
  offset?: number;
  place?: PlacesType;
};

function Tooltip({type = 'default', content, offset, children, place, isOpen = false}: IProps): ReactElement {
  const {remoteTooltip} = useContext(CommonContext);
  const id = getUniqueKey();
  const ref = useRef<TooltipRefProps>(null);

  useEffect(() => {
    if (type === 'browser-permission') {
      if (remoteTooltip) {
        ref.current?.open({
          content: content || remoteTooltip,
          place: 'bottom-start',
          position: {x: 135, y: 0}
        });
      } else {
        ref.current?.close();
      }
    }
  }, [type, content, remoteTooltip]);

  if (type === 'browser-permission') {
    // browser permission 전용
    return (
      <ReactTooltip ref={ref} border={borderStyle} style={tooltipStyle}>
        {content}
      </ReactTooltip>
    );
  } else if (type === 'guide') {
    // guide type (이미지 등 ReactElement 를 표시하여야 할 경우)
    return (
      <>
        <TooltipTrigger data-tooltip-id={id}>{children}</TooltipTrigger>
        <ReactTooltip border={borderStyle} style={tooltipStyle} place={place} id={id}>
          {content}
        </ReactTooltip>
      </>
    );
  } else if (type === 'button') {
    // button type (tooltip 내 버튼을 사용하는 경우)
    return (
      <>
        <TooltipTrigger data-tooltip-id={id}>{children}</TooltipTrigger>
        <ReactTooltip clickable={true} border={borderStyle} style={tooltipStyle} place={place} id={id}>
          {content}
        </ReactTooltip>
      </>
    );
  } else if (type === 'notice') {
    return (
      <>
        <TooltipTrigger data-tooltip-id={id} data-tooltip-offset={offset}>
          {children}
        </TooltipTrigger>
        <ReactTooltip isOpen={isOpen} border={borderStyle} style={tooltipStyle} place={place} id={id}>
          {content}
        </ReactTooltip>
      </>
    );
  }

  // 기본 형태
  return (
    <>
      <TooltipTrigger data-tooltip-id={id} data-tooltip-offset={offset} data-tooltip-content={content as string}>
        {children}
      </TooltipTrigger>
      <ReactTooltip border={borderStyle} style={tooltipStyle} place={place} id={id} />
    </>
  );
}

export default Tooltip;
